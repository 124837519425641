<template>
    <div>
        <v-row>
            <v-col>
                <app-list />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import ListChannels from '../components/ListChannels.vue'

export default {
    components: {
        'app-list': ListChannels
    }
}
</script>

<style>

</style>