import Vue from 'vue'

export async function getArtistChannels() {
    const id = localStorage.id_profile_entity
    const response = await Vue.prototype.$httpDist.get(`/artist/${id}/channel`);
    return response
}

export async function postChannel(url) {
    const id = localStorage.id_profile_entity
    const response = await Vue.prototype.$httpDist.post(
        `/artist/${id}/channel`,
        { "url": url }
    );
    return response
}