<template>
    <div>
        <v-dialog v-model="dialog" persistent max-width="600px">
        <template v-slot:activator="{ on, attrs }">
            
            <v-btn

                icon="mdi-music"
                class="submit-button"
                height="40"
                @click="dialog = false;"
                :color="music.musicFile.length < 1 ? '#994995' : ' opacity : 0.5;'" v-bind="attrs" v-on="on"
            >
                <v-icon>mdi-file-music</v-icon>
                <span>Selecionar Arquivo</span>
            </v-btn>
        </template>
        <v-card color="#071c33" dark>
            <v-card-text>
                <v-form ref="form" v-model="hableSubmit">
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <div class="d-flex justify-end align-center mt-2">
                                    <v-icon size="24px" class="pointer" @click="dialog = false;">mdi-close</v-icon>
                                </div>
                            </v-col>
                            <v-col cols="12">
                                <h2 class="white--text text-center">Adicionar arquivo de áudio para a música "{{music.no_title}}"</h2>
                            </v-col>
                            <v-col cols="12" class="mt-6">
                                <v-file-input
                                    accept="audio/wav"
                                    placeholder="Adicionar arquivo de áudio"
                                    prepend-icon="mdi-music"
                                    label="Formato suportado .WAV"
                                    v-model="musicFile"
                                    required
                                    :rules="[rules.required]"
                                    :clearable="false"
                                ></v-file-input>
                            </v-col>
                            <v-col>
                                <div class="mt-4 d-flex justify-center">
                                    <v-btn
                                        :disabled="!hableSubmit"
                                        class="submit-button"
                                        height="40"
                                        @click="setMusicPreview()"
                                    >
                                        <span>Adicionar</span>
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>
        </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    props: {
        index: {
            type: Number,
            required: true
        },
        music: {
            type: Object,
            required: true
        }
    },
    data: () => ({
        loading: false,
        dialog: false,
        musicFile: null,
        hableSubmit: false,
        rules: {
            required: value => !!value || 'Este campo é obrigatório.'
        },
    }),
    methods: {
        setMusicPreview() {
            const track = {
                index: this.index,
                track: this.musicFile
            }
            this.$emit('setMusicFile', track);
            this.dialog = false;
        },
    }
};
</script>

<style scoped>
    .submit-button {
        width: 250px !important;
    }
</style>