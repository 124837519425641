<template>
    <div>
        <div class="d-flex justify-space-between">
            <h3 class="white--text">CANAIS CADASTROS</h3>
            <v-btn
                class="submit-button"
                outlined
                dark
                color="white"
                height="40"
                width="250"
                @click="toggleInput"
            >
                {{ btnNewChannel }}
            </v-btn>
        </div>
        <div v-show="showChannelInput">
            <input-channels 
                class="mt-4"
                label="URL do canal"
                :array="newChannelsURLs"
            />
            <v-btn
                class="clear-button mt-3"
                outlined
                dark
                color="white"
                height="40"
                @click="register"
            >
                Salvar
            </v-btn>
        </div>
        <v-card dark class="mt-4" color="#071c33">
            <v-col md="6" class="mb-4">
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Buscar Canal"
                    single-line
                    hide-details
                ></v-text-field>
            </v-col>
            <v-data-table
                item-key="id_channel"
                class="elevation-1 bg-table"
                loading-text="Carregando..."
                :loading="loading"
                :headers="headers"
                :search="search"
                :items="channels"
            />
        </v-card>
        <app-notification
            @notificationStatus="changeNotificationStatus"
            :active="notification.active"
            :type="notification.type"
            :message="notification.message"
        />
    </div>
</template>

<script>

import { getArtistChannels, postChannel } from '../services/Channel'
import IncrementableInput from './DinamicInput.vue';
import Notification from './Notification.vue';

export default {
    components: {
        'input-channels': IncrementableInput,
        'app-notification': Notification
    },
    created() {
        this.getArtistChannels();
    },
    data: () => ({
        loading: false,
        headers:  [
          { text: 'URL do Canal', value: 'Url', sortable: true, filterable: true },
        ],
        channels: [],
        search: '',
        showChannelInput: false,
        newChannelsURLs: [''],
        btnNewChannel: 'Associar novo canal',
        notification: {
            active: false,
            message: '',
            type: ''
        }
    }),
    methods: {
        getArtistChannels() {
            this.loading = true;
            const channelResponse = getArtistChannels();
            channelResponse
            .then(res => {
                const { data } = res.data;
                this.channels = data;
            })
            .catch(err => {
                console.log(err);
                this.notification = {
                    active: true,
                    message: 'Falha ao listar canais',
                    type: 'error'
                }
            })
            .finally(() =>{
                this.loading = false;
            })
        },
        toggleInput() {
            if(this.showChannelInput){
                this.btnNewChannel = 'Associar novo canal'
                this.showChannelInput = false
            } else {
                this.btnNewChannel = 'Cancelar'
                this.newChannelsURLs = ['']
                this.showChannelInput = true
            }
        },
        register() {
            if(this.validate(this.newChannelsURLs)){
                this.showChannelInput = false
                this.btnNewChannel = 'Associar novo canal'

                for(let url of this.newChannelsURLs){
                    postChannel(url)
                    .then(() => {
                        this.notification = {
                            active: true,
                            message: 'Canal cadastrado com sucesso!',
                            type: 'success'
                        }
                    })
                    .catch(err => {
                        console.log(err)
                        this.notification = {
                            active: true,
                            message: 'Falha ao cadastrar novo canal',
                            type: 'error'
                        }
                    })
                }
                this.newChannelsURLs = ['']
            } else {
                this.notification = {
                    active: true,
                    message: 'A URL fornecida é inválida',
                    type: 'error'
                }
            }
        },
        validate(inputs){
            for(let input of inputs){
                let pattern = /(https:\/\/)?(www.)?youtube.com\/(channel|c|user)\/.*/g
                let match = pattern.exec(input) 
                if(!match) return false
            }
            return true
        },
        changeNotificationStatus() {
            this.notification = {
                active: false,
                message: '',
                type: ''
            }
        }
    }
}
</script>

<style>
    .bg-table {
        background-color: #071c33 !important;
    }
</style>