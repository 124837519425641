import Vue from 'vue'

export async function getUpc() {
    const response = await Vue.prototype.$httpDist.get('/upc');
    return response
}

export async function postUpc(upcCode) {
    const response = await Vue.prototype.$httpDist.post('/upc', {
        "co_upc":upcCode,
        "id_artist":23
});
    return response
}


export async function deleteUpc(IdUpc) {
    const response = await Vue.prototype.$httpDist
    .delete('/upc/' + IdUpc);
    return response
}


export async function putUpc(upc) {
    const response = await Vue.prototype.$httpDist
    .put('/upc/' + upc.id, {
        "IdUpc": parseInt(upc.id),
        "co_upc":upc.co_upc,
        "id_artist": upc.id_artist
    });
    return response
}


export function errorMessage(status) {
    let message = "";
    switch (status) {
        case 409:
            message = 'Este email já está sendo utilizado! Tente novamente com um email diferente.'
            break;

        case 500:
            message = 'Algo deu errado. Já estamos resolvendo.'
            break;

        default:
            message = 'Aconteceu um probleminha, não se preocupe. Já estamos resolvendo.'
            break;
    }
    return message
}