<template>
    <div>
        <app-loading :active="loading"></app-loading>
        <v-form
            ref="form"
            v-model="hableSubmit"
        >
            <v-row dense>
                <v-col cols="12" lg="12">
                     <v-text-field
                        v-model="newPassword"
                        :rules="[rules.required, rules.min, rules.capitalLetter, rules.specialCharacter, rules.number, ]"
                        :type="'password'"
                        name="new password"
                        required
                        dark
                        label="Senha"
                        class="fluid"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" lg="12">
                    <v-text-field
                        v-model="confirmPassword"
                        :rules="[rules.required, ((newPassword == confirmPassword ? true: false )  || 'As senhas precisam ser iguais.')]"
                        :type="show3 ? 'text' : 'password'"
                        :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                        name="confirm password"
                        required
                        dark
                        label="Confirmar Senha"
                        @click:append="show3 = !show3"
                        class="fluid"
                    ></v-text-field>
                </v-col>
            </v-row>
            <div class="mt-12 d-flex justify-end">
                <v-btn
                    class="mr-4 clear-button"
                    outlined
                    dark
                    color="white"
                    height="40"
                    @click="reset"
                >
                    Limpar
                </v-btn>
                <v-btn
                    :disabled="!hableSubmit"
                    class="submit-button"
                    height="40"
                    @click="submit"
                >
                    <span v-if="loading">Salvando</span>
                    <span v-else>Salvar</span>
                </v-btn>
            </div>
        </v-form>
        <app-notification 
            @notificationStatus="changeNotificationStatus" 
            :active="notification.active"
            :type="notification.type"
            :message="notification.message"    
        >
        </app-notification>
    </div>
</template>

<script>
import PageLoading from './PageLoading.vue';
import Notification from './Notification.vue';
import { putPassword } from '../services/User.js';

export default {
    components: { 
        'app-loading': PageLoading,
        'app-notification': Notification
    },
    data: () => ({
        loading: false,
        hableSubmit: true,
        firstName: '',
        secondName: '',
        email: '',
        newPassword: '',
        show3: false,
        confirmPassword: '',
        type: 'Artist',
        basicInformation: {
            interpreter: '',
            mainGender: '',
            secondaryGender: '',
            typeOfPoetryOnYoutube: '',
            biography: ''
        },
        genders: ['Rock', 'Axé', 'Funk', 'Gospel', 'Eletrônica'],
        typesOfPoetry: ['Política 1', 'Política 2', 'Política 3'],
        rules: {
            required: value => !!value || 'Este campo é obrigatório.',
            capitalLetter: value =>  /^(?=(?:.*?[A-Z]){1})/.test(value) || 'Sua senha deve conter no mínimo uma letra maiúscula.',
            number: value =>  /^(?=(?:.*?[0-9]){1})/.test(value) || 'Sua senha deve conter no mínimo um caractere alfanumérico.',
            specialCharacter: value =>  /^(?=(?:.*?[!@#$%*()_+^&}{:;?.]){1})(?!.*\s)[0-9a-zA-Z!@#$%;*(){}_+^&]*$/.test(value) || 'Sua senha deve conter no mínimo um caractere especial.',
            min: v => v.length >= 6 || 'Sua senha precisa ter no mínimo 6 caracteres'
        },
        notification: {
            active: false,
            message: '',
            type: ''
        }
    }),
    methods: {
        reset () {
            this.$refs.form.reset()
        },
        submit() {
            this.loading = true;
            this.hableSubmit = false;
            if(this.$refs.form.validate()) {
                const token = atob(this.$route.params.token);
                const params = {
                    "id_user": parseInt(this.$route.params.id),
                    "ds_password": this.newPassword
                }
                const userPutResponse = putPassword(params, token, this.$route.params.id);
                userPutResponse
                .then(() => {
                    this.notification = {
                        active: true,
                        message: 'Cadastro completo com sucesso!',
                        type: 'success'
                    };
                    setTimeout(() => {
                        window.location.href = '/login';
                    }, 2000)
                })
                .catch(() => {
                    this.notification = {
                        active: true,
                        message: 'Erro ao completar cadastro. Por favor, tente novamente em um instante.',
                        type: 'error'
                    }
                })
                .finally(() =>{
                    this.loading = false;
                    this.hableSubmit = true;
                })
            }
        },
        changeNotificationStatus() {
            this.notification = {
                active: false,
                message: '',
                type: ''
            }
        }
    }
}
</script>

<style scoped lang="scss">
    @import "../sass/__form.scss"; 
</style>