import Vue from 'vue'

export async function getLabels() {
    const response = await Vue.prototype.$httpDist.get('/label');
    return response
}

export async function postLabel(user) {
    if (user.no_last_name === null) {
        delete user.no_last_name;
    }
    console.log(user)
    const response = await Vue.prototype.$httpDist
    .post('/label', {
        id_aggregator: 1,
        no_label: `${user.no_first_name} ${user.no_last_name}`,
        user: user
    });
    return response
}

export async function putLabel(label) {
    const response = await Vue.prototype.$httpDist
    .put('/label/' + label.id, {
        "id_label": parseInt(label.id),
        "no_label": label.no_label,
        "ds_about": ""
    });
    return response
}

export async function deleteLabel(id) {
    const response = await Vue.prototype.$httpDist
    .delete('/label/' + id);
    return response
}
