<template>
    <v-form 
        ref="form"
        v-model="hableSubmit"
    >
        <div>
            <div>
                <div class="d-flex justify-end">

                    <v-btn
                            class="mr-4 d-flex justify-end clear-button button-clear-size"
                            outlined
                            dark
                            color="white"
                            height="40"
                            @click="$router.go(-1)"
                        >
                            <span>Voltar</span>
                        </v-btn>
                
                        <v-btn
                            class="submit-button"
                            outlined
                            dark
                            color="white"
                            height="40"
                            width="250"
                            @click="toggleInput"
                        >
                            {{ UPC }}
                        </v-btn>
            </div>
            </div>
            <div v-show="showUPCInput">
                <input-UPCs 
                    label="Código de Barras (UPC)"
                    :type="'number'"
                    dark
                    :counter="13"
                    :rules="rules.organizationRules"
                    :array="newUPCs"
                />
                <v-btn
                    :disabled="!hableSubmit"
                    class="submit-button"
                    height="40"
                    @click="creatUpc"
                >
                    <span v-if="loading">Salvando</span>
                    <span v-else>Salvar</span>
                </v-btn>
            </div>
            <v-card dark class="mt-4" color="#071c33">
                <v-col md="6" class="mb-4">
                    <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Buscar UPC"
                        single-line
                        hide-details
                    ></v-text-field>
                </v-col>
                <v-data-table
                    item-key="id_UPC"
                    class="elevation-1 bg-table"
                    loading-text="Carregando..."
                    :loading="loading"
                    :headers="headers"
                    :search="search"
                    :items="UPCs"
                >
                    <template v-slot:item.actions="{ item }">
                        <v-icon
                            small
                            class="mr-2"
                            @click="deleteUpc(item.IdUpc)"
                        >
                            mdi-delete
                        </v-icon>
                    </template>  
                </v-data-table>
            </v-card>
            <app-notification
                @notificationStatus="changeNotificationStatus"
                :active="notification.active"
                :type="notification.type"
                :message="notification.message"
            />
        </div>
    </v-form>
</template>

<script>

import { getUpc,deleteUpc,postUpc,  errorMessage} from '../services/Upc'
import IncrementableInputupc from './IncrementableInputupc.vue';
import Notification from './Notification.vue';

export default {
    components: {
        'input-UPCs': IncrementableInputupc,
        'app-notification': Notification
    },
    created() {
        this.getListUpc();
        // this.creatUpc();
        // this.deleteUpc();
    },
    data: () => ({
        loading: false,
        headers:  [
          { text: 'ID UPC', value: 'IdUpc', sortable: true, filterable: true },
          { text: 'CÓDIGO UPC', value: 'co_upc', sortable: true, filterable: true },
          { text: 'Ações', align: 'center', value: 'actions', sortable: false, filterable: false },
        ],
        UPCs: [],
        hableSubmit: false,
        search: '',
        showUPCInput: false,
        newUPCs: [''],
        upc: '',
        sla:'',
        UPC: 'CADASTRAR CÓDIGO UPC',
        notification: {
            active: false,
            message: '',
            type: ''
        },
        rules: {
            required: value => !!value || 'Este campo é obrigatório.',
            requiredFile: [v => (v && v.size > 0) || 'O arquivo é obrigatório'],
            organizationRules: [
            (v) => !!v || "Este campo é obrigatório.",
            (v) =>
            (v && v.length == 13) ||
            "O código UPC deve ter 13 números",
        ],
        }
    }),
    methods: {
         getListUpc() {
            this.loading = true;
            const upcResponse = getUpc();
            upcResponse
            .then(res => {
                const { data } = res.data;
                this.UPCs = data;
                
            })
            .catch(err => {
                console.log(err);
            })
            .finally(() =>{
                this.loading = false;
            })
        },
        toggleInput() {
           if(this.showUPCInput){
               this.UPC = 'CADASTRAR CÓDIGO UPC'
               this.showUPCInput = false
           } else {
                this.UPC = 'Cancelar'
                 this.newUPCs = ['']
                this.showUPCInput = true
            }
        },
       creatUpc() {
           for(let upcCode of this.newUPCs){
                var newUpcInt = parseInt(upcCode)
                postUpc(newUpcInt)
            }
             this.notification = {
                        active: true,
                        message: "Upc cadastrado com sucesso!",
                        type: 'success'
                    };
                this.reset();
                this.loading = false;
                this.hableSubmit = true;
        },
        deleteUpc(IdUpc) {
            this.loading = true;
            const reqArtist = deleteUpc(IdUpc);
            reqArtist
            .then(() => {
                this.notification = {
                    active: true,
                    message: "Upc deletado com sucesso!",
                    type: 'success'
                };
                this.reset();
            })
            .catch(err => {
                this.notification = {
                    active: true,
                    message: errorMessage(err.response.status),
                    type: 'error'
                }
            })
            .finally(() =>{
                this.loading = false;
                this.hableSubmit = true;
            })
        },
        changeNotificationStatus() {
            this.notification = {
                active: false,
                message: '',
                type: ''
            }
        }
    }
}
</script>

<style>
    .bg-table {
        background-color: #071c33 !important;
    }
    .submit-button {
        max-width: 250px !important;
    }
</style>