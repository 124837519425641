<template>
    <div>
        <div v-for="(item, index) in array" :key="index">
            <v-text-field
                v-model="array[index]"
                :label="label"
                dark
                :append-icon="array.length > 1 ? 'mdi-minus-circle' : ''"
                @click:append="removeInput(index)"
            />
        </div>
        <div class="d-flex align-center" @click="addInput">
            <v-icon class="mr-3 white--text">
                mdi-plus-circle
            </v-icon>
            <span class="white--text">Adicionar canal</span>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        array: {
            type: Array,
            required: true
        },
        label: {
            type: String,
            required: true
        }
    },
    methods: {
        addInput() {
            this.array.push('')
        },
        removeInput(index) {
            this.array.splice(index, 1)
        }
    }
}
</script>