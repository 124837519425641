<template>
  <div>
    <h3 class="white--text">CÓDIGOS UPC</h3>
    <div class="mt-12">
      <app-register-user-form></app-register-user-form>
    </div>
  </div>
</template>

<script>
import RegisterUpcForm from "../components/RegisterUpcForm.vue";

export default {
  components: {
    "app-register-user-form": RegisterUpcForm,
  },
};
</script>

<style></style>
