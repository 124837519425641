<template>
    <div>
        <div v-for="(item, index) in array" :key="index">
            <v-text-field
                v-model="array[index]"
                :label="label"
                dark
                :type="'number'"
                :counter="13"
                :rules="rules.organizationRules"
            />
        </div>
        <v-icon
            class="mr-1" 
            color="white"
            @click="addInput"
        >mdi-plus</v-icon>
        <v-icon 
            v-if="array.length > 1"
            color="red"
            @click="removeInput"
        >mdi-close</v-icon>
    </div>
</template>

<script>

export default {
    props: {
        array: {
            type: Array,
            required: true
        },
        label: {
            type: String,
            required: true
        }
    },
    data: () => ({
        rules: {
            organizationRules: [
            (v) => !!v || "Este campo é obrigatório.",
            (v) =>
            (v && v.length == 13) ||
            "O código UPC deve ter 13 números",
        ],
        }
    }),
    methods: {
        addInput() {
            this.array.push('')
        },
        removeInput() {
            this.array.pop()
        }
    }
}
</script>