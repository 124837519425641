<template>
    <v-form 
        ref="form"
        v-model="hableSubmit"
    >
        <div class="bg-card-content">
            <h3 class="white--text mb-8 text-uppercase">INFORMAÇÕES BÁSICAS</h3>
            <div>
                <v-row>
                    <v-col cols="12" lg="6">
                        <v-text-field
                            v-model="trackTitle"
                            label="Título"
                            required
                            :rules="[rules.required]"
                            dark
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="6">
                        <v-select
                            label="Tipo"
                            :items="trackTypeList"
                            item-text="ds_track_version_type"
                            item-value="id_track_version_type"
                            v-model="trackTypeSelected"
                            :rules="[rules.required]"
                            required
                            dark
                        ></v-select>
                    </v-col>
                    <v-col cols="12">
                        <v-checkbox
                            v-model="hasMultipleArtists"
                            label="Essa música tem a participação de múltiplos artistas"
                        ></v-checkbox>
                    </v-col>
                </v-row>
                <div v-if="hasMultipleArtists">
                    <h4 class="white--text mb-4">Participação</h4>
                    <v-row v-for="(participation, index) in participations" :key="index">
                        <v-col cols="12" lg="6">
                            <v-select
                                label="Tipo de participação"
                                :items="participationType"
                                item-text="desc"
                                item-value="code"
                                v-model="participation.type"
                                :rules="[rules.required]"
                                required
                                dark
                            ></v-select>
                        </v-col>
                        <v-col cols="12" lg="6">
                                <v-text-field
                                label="Artista"
                                v-model="participation.artist"
                                :rules="[rules.required]"
                                required
                                dark
                                :append-icon="participations.length > 1 ? 'mdi-minus-circle' : ''"
                                @click:append="participations.splice(index, 1)"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-col cols="12">
                        <div class="d-flex align-center pointer" @click="participations.push({type: '', artist: ''})">
                            <v-icon class="mr-3">
                                mdi-plus-circle
                            </v-icon>
                            <span class="white--text">Adicionar artista</span>
                        </div>
                    </v-col>
                </div>
            </div>
        </div>
        <div class="bg-card-content">
            <h3 class="white--text mb-8 text-uppercase">GÊNERO</h3>
            <div>
                <v-row>
                    <v-col cols="12" md="6">
                        <v-select
                            dark
                            :rules="[rules.required]"
                            v-model="mainGenre"
                            :items="primaryGenres"
                            item-text="no_genre"
                            item-value="id_genre"
                            label="Gênero Principal"
                        ></v-select>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-select
                            dark
                            :rules="[rules.required]"
                            v-model="secondaryGenre"
                            :items="secondaryGenres"
                            item-text="no_genre"
                            item-value="id_genre"
                            label="Gênero Secundário"
                        ></v-select>
                    </v-col>
                </v-row>
            </div>
        </div>
        <div class="bg-card-content">
            <h3 class="white--text mb-8 text-uppercase">INFORMAÇÕES AVANÇADAS</h3>
            <div>
                <v-row>
                    <v-col cols="12" md="6">
                        <v-select
                            dark
                            :rules="[rules.required]"
                            v-model="language"
                            :items="languages"
                            item-text="no_language"
                            item-value="id_language"
                            label="Idioma da música"
                        ></v-select>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                            label="ISRC"
                            :rules="[rules.required]"
                            required
                            dark
                            v-model="isrc"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" lg="8">
                        <v-textarea
                            :rules="[rules.required]"
                            v-model="lyrics"
                            label="Letra da música"
                            auto-grow
                            dark
                        ></v-textarea>
                    </v-col>
                    <v-col cols="12" lg="4">
                        <v-checkbox
                            v-model="instrumental"
                            label="Instrumental"
                        ></v-checkbox>
                        <v-checkbox
                            v-model="explicit"
                            label="Conteúdo Explícito"
                        ></v-checkbox>
                    </v-col>
                </v-row>
            </div>
        </div>
        <div class="bg-card-content">
            <h3 class="white--text mb-8 text-uppercase">CRÉDITOS DA MÚSICA</h3>
            <div>
                <v-row>
                    <v-col cols="12" lg="4">
                        <div v-for="(producer, index) in credits.producers" :key="index">
                            <v-text-field
                                label="Produtor"
                                v-model="credits.producers[index]"
                                required
                                :rules="[rules.required]"
                                dark
                                :append-icon="credits.producers.length > 1 ? 'mdi-minus-circle' : ''"
                                @click:append="credits.producers.splice(index, 1)"
                            ></v-text-field>
                        </div>
                        <div class="d-flex align-center pointer" @click="credits.producers.push('')">
                            <v-icon class="mr-3">
                                mdi-plus-circle
                            </v-icon>
                            <span class="white--text">Adicionar produtor</span>
                        </div>
                    </v-col>
                    <v-col cols="12" lg="8">
                        <v-row>
                            <v-col cols="12" lg="6">
                                <v-text-field
                                    v-for="(composer, index) in credits.composers" :key="index"
                                    label="Compositor"
                                    v-model="credits.composers[index].name"
                                    required
                                    :rules="[rules.required]"
                                    dark
                                    :append-icon="credits.composers.length > 1 ? 'mdi-minus-circle' : ''"
                                    @click:append="credits.composers.splice(index, 1)"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" lg="6">
                                <v-text-field
                                    v-for="(composer, index2) in credits.composers" :key="index2"
                                    label="Editora"
                                    v-model="credits.composers[index2].publisher"
                                    required
                                    :rules="[rules.required]"
                                    dark
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <div class="d-flex align-center pointer" @click="credits.composers.push({name: '', publisher: ''})">
                            <v-icon class="mr-3">
                                mdi-plus-circle
                            </v-icon>
                            <span class="white--text">Adicionar compositor e editora</span>
                        </div>
                    </v-col>
                </v-row>
            </div>
        </div>
        <div class="mt-12 d-flex justify-end">
            <v-btn
                :disabled="!hableSubmit"
                class="submit-button"
                height="40"
                @click="sendInfo()"
            >
                <span>Adicionar</span>
            </v-btn>
        </div>
    </v-form>
</template>

<script>
import { getTrackType } from '../services/Track.js';
import { getGenre } from '../services/Genre';
import { getLanguage } from '../services/Location';
import { getArtistById } from '../services/Artist';

export default {
    created() {
        this.getTrackType();
        this.getGenreList();
        this.getLanguageList();
        this.artistSelected = parseInt(localStorage.id_profile_entity);
        this.getArtistData();
    },
    props: {
        album: {
            required: false,
            type: Object
        },
        musics: {
            required: false,
            type: Array
        }
    },
    data: () => ({
        select: [],
        hableSubmit: false,
        trackTitle: '',
        trackTypeList: [],
        trackTypeSelected: '',
        hasMultipleArtists: false,
        participations: [],
        participationType: [{code: 8, desc: 'Feat.'}, {code: 5, desc: 'Colab.'}],
        credits: {
            producers: [''],
            composers: [{name: '', publisher: ''}],
        },
        primaryGenres: [],
        secondaryGenres: [],
        mainGenre: '',
        secondaryGenre: '',
        language: '',
        languages: [],
        artistSelected: null,
        instrumental: false,
        explicit: false,
        isrc: '',
        lyrics: '',
        rules: {
            required: value => !!value || 'Este campo é obrigatório.'
        }
    }),
    methods: {
        setTrackInfo() {
            const trackData = {
                "participations": this.participations,
                "credits": this.credits,
                "musicFile": [],
                "musicUrl": "",
                "no_title": this.trackTitle,
                "version_type": {
                    "id_track_version_type": this.trackTypeSelected
                },
                "genre": {
                    "id_genre": this.mainGenre
                },
                "sub_genre": {
                    "id_genre": this.secondaryGenre
                },
                "is_explicit": this.explicit,
                "is_streamable": true,
                "is_instrumental": this.instrumental,
                "is_downloadable": true,
                "is_available_separately": true,
                "nu_order_track": '',
                "tp_track_price": "HIGH",
                "language": {
                    "id_language": this.language
                },
                "recording": {
                    "co_isrc": this.isrc,
                    "composition": {
                        "no_title": this.trackTitle,
                        "ds_lyric": this.lyrics
                    }
                }
            };
            return trackData
        },
        sendInfo() {
            this.$emit("track", this.setTrackInfo());
            this.$emit('setAddMusicFalse');
        },
        getTrackType() {
            const reqTrackType = getTrackType();
            reqTrackType
            .then(res => {
                this.trackTypeList = res.data.data;
            })
            .catch(err => {
                console.log(err);
            })
        },
        getGenreList() {
            const genreResponse = getGenre();
            genreResponse
            .then(res => {
                const { data } = res.data;
                this.primaryGenres = data.filter( genre => genre.is_subgenre == false);
                this.secondaryGenres = data.filter( genre => genre.is_subgenre == true);
            })
            .catch(err => {
                console.log(err);
            })
        },
        getLanguageList() {
            const languageResponse = getLanguage();
            languageResponse
            .then(res => {
                const { data } = res.data;
                this.languages = data.map( language => ({
                    "id_language": language.id_language,
                    "no_language": language.no_language
                }))
            })
            .catch(err => {
                console.log(err);
            })
        },
        getArtistData() {
            this.loading = true;
            const artistResponse = getArtistById(this.artistSelected);
            artistResponse
            .then(res => {
                const { data } = res.data;    
                this.labelName = data.label ? data.label.no_label : 'Artista independente';
                this.artistName = data.no_artistic_name;
                this.labelSelected = btoa(JSON.stringify({"id_label": parseInt(data.id_label)}));

                if (data.params) {
                    const params = JSON.parse(data.params);
                    this.mainGenre = params.mainGenre;
                    this.secondaryGenre = params.secondaryGenre;
                    this.language = params.language;
                }
            })
            .catch(err => {
                console.log(err);
            })
            .finally(() =>{
                this.loading = false;
            })
        }
    }
}
</script>

<style scoped lang="scss">
    .bg-card-content {
        background-color: rgb(0 0 0 / 41%);
        padding: 2rem;
        margin-bottom: 30px;
        height: auto;
    }
    ul {
        padding-left: 14px;

        li {
            font-size: 0.875rem;
            color: white;
        }
    }
    .fluid-div {
        width: 100%;
    }
    .pointer {
        cursor: pointer;
    }
    .cover-button {
        width: 70%;
        min-width: 158px !important;
        border-radius: 100px !important;
    }
    .submit-button {
        max-width: 250px !important;
    }
</style>