<template>
    <div>
        <app-loading-full :active="loading" :text="desc"></app-loading-full>
        <v-row>
            <v-col cols="12" lg="8">
                <div class="bg-card-content">
                <h3 class="white--text">DETALHES DA PUBLICAÇÃO</h3>
                <v-row class="mt-6">
                    <v-col cols="12" sm="4" lg="5" xl="4">
                        <img :src="albumCover" width="100%" height="100%">
                    </v-col>
                    <v-col cols="12" sm="8" lg="7" xl="8">
                        <div class="border-bottom">
                            <span class="album-title">{{album.no_title}}</span>
                        </div>
                        <div class="d-flex flex-column desc-album mt-4">
                            <span>Artista: {{albumDetails.artistName}}</span>
                            <span>Selo: {{albumDetails.labelName}}</span>
                            <span>Tipo: {{albumDetails.albumTypeName}}</span>
                        </div>
                        <div class="desc-album-opc d-flex flex-column mt-12">
                            <span>Gênero Principal:  {{albumDetails.mainGenreName}}</span>
                            <span>Data de lançamento:  {{moment(album.dt_release ? album.dt_release : album.dt_original_release).utc().format('DD/MM/YYYY')}}</span>
                            <span>UPC: {{album.co_upc}}</span>
                        </div>
                    </v-col>
                </v-row>
                <div class="border-bottom mt-6">
                    <h3 class="white--text">FAIXAS DO ÁLBUM</h3>
                </div>
                <v-row class="mt-2">
                    <v-col cols="12">
                        <v-data-table
                            class="bg-table"
                            :headers="headers"
                            :items="musics"
                            :items-per-page="25"
                            hide-default-footer
                            dark
                            dense
                        >
                            <template v-slot:item.order_track="{ index }">
                                <span>{{index + 1}}</span>
                            </template>
                            <template v-slot:item.audio="{ item, index }">
                                <div>
                                    <audio :id="'play-' + index" :src="item.musicUrl" type="audio/wav"></audio>
                                    <v-icon size="24" @click="playMusic(index)" color="#994995">mdi-play-circle</v-icon>
                                </div>
                            </template>
                            <template v-slot:item.participations="{ item }">
                                <span v-for="(participation, index) in item.participations" :key="index">
                                    <span v-if="participation.type">
                                        {{ participation.type == 5 ? 'Collab.' : 'Feat.'}} {{participation.artist}} {{ (index + 1) == item.participations.length  ? '' : ' / '}}
                                    </span>
                                    <span v-else>
                                        --
                                    </span>
                                </span>
                            </template>
                            <template v-slot:item.isrc="{ item }">
                                <span>{{item.recording.co_isrc}}</span>
                            </template>
                            <template v-slot:item.actions="{ item, index }">
                                <app-delete-music :nameMusic="item.no_title" :index="index" @removeMusic="removeMusic($event)"></app-delete-music>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
                </div>
            </v-col>
            <v-col cols="12" lg="4">
                <app-metadata-resume :details="false" :musics="musics"></app-metadata-resume>
            </v-col>
        </v-row>
        <div class="mt-6 d-flex justify-end flex-wrap-reverse">
            <v-btn
                class="mr-4 clear-button button-clear-size"
                outlined
                dark
                color="white"
                height="40"
                @click="$emit('decrement');"
            >
                <span>Voltar</span>
            </v-btn>
            <v-btn
               @click="createAlbum()"
                class="submit-button"
                height="40"
            >
                <span>Cadastrar</span>
            </v-btn>
        </div>
        <app-notification 
            @notificationStatus="changeNotificationStatus" 
            :active="notification.active"
            :type="notification.type"
            :message="notification.message"    
        >
        </app-notification>
    </div>
</template>

<script>
import moment from 'moment';
import MetadataResume from '../components/MetadataResume.vue';
import { getAlbumArtworkUrl, postAlbum, putAlbumArtwork, putAlbumDelivery, deleteAlbum } from '../services/Album';
import { deleteUpc, putUpc} from '../services/Upc'
import { getTrackFileUrl, putTrackFile, postTrack } from '../services/Track';
import DeleteMusicVue from './DeleteMusic.vue';
import LoadingFull from '../components/LoadingFull.vue';
import Notification from '../components/Notification.vue';

export default {
    components: {
        'app-delete-music': DeleteMusicVue,
        'app-loading-full': LoadingFull,
        'app-notification': Notification,
        "app-metadata-resume": MetadataResume,
    },
    props: {
        album: {
            type: Object,
            required: true
        },
        musics: {
            type: Array,
            required: true
        },
        musicsUploaded: {
            type: Number,
            required: true,
            default: 0
        },
        albumCover: {
            required: true,
            type: String
        },
        albumDetails: {
            type: Object,
            required: true
        }
    },
    data: () => ({
        moment: moment,
        headers: [
          { text: ' ', align: 'start', sortable: false, value: 'audio'},
          { text: '#', sortable: false, value: 'order_track' },
          { text: 'Faixa', sortable: false, value: 'no_title' },
          { text: 'Participações', align: 'center', sortable: false, value: 'participations' },
          { text: 'ISRC', sortable: false, value: 'isrc' },
          { text: 'Ações', sortable: false, align: 'end', value: 'actions' }
        ],
        onPlay: false,
        cover: [],
        loading: false, 
        desc: 'Carregando...',
        notification: {
            active: false,
            message: '',
            type: ''
        } 
    }),
    methods: {
        changeNotificationStatus() {
            this.notification = {
                active: false,
                message: '',
                type: ''
            }
        },
        playMusic(index) {
            const audio = document.getElementById('play-' + index);
            if (!this.onPlay) {
                this.onPlay = true;
                audio.play();
            } else {
                this.onPlay = false;
                audio.pause();
            }
        },
        removeMusic(index) {
            this.musics.splice(index, 1);
        },
        createAlbum() {
            this.loading = true;
            this.desc = 'Cadastrando álbum...';
            if (this.albumDetails.albumMembers.length > 0) {
                const members = [];
                this.albumDetails.albumMembers.map(member => {
                    if(member.artist !='' ) {
                        members.push({
                            "artist_member": {
                                "no_member": member.artist
                            },
                            "id_member_function": member.type
                        })
                    }
                });
                this.album.members = members;
            }
            if (this.album.co_upc == ''){
                delete this.album.co_upc;
            }

            const albumResponse = postAlbum(this.album);
            albumResponse
            .then(res => {
                const { data } = res.data;
                const idVolume = data.volumes[0].id_album_volume;
                const idAlbum = data.volumes[0].id_album;
                
                this.addArtwork(idAlbum);
                this.createTrack(idVolume, idAlbum);
                this.updateUpc();
                this.deleteUpc();
            })
            .catch(() => {
                this.notification = {
                    active: true,
                    message: "Ops! Ocorreu um erro no cadastro do álbum. Tente novamente.",
                    type: 'error'
                }
                this.loading = false;
                window.scrollTo(0, 0);
            })
        },
        createBlob(b64Data, contentType) {
            const b64toBlob = (b64Data, contentType='', sliceSize=512) => {
                const byteCharacters = atob(b64Data);
                const byteArrays = [];
                for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                    const slice = byteCharacters.slice(offset, offset + sliceSize);
                    const byteNumbers = new Array(slice.length);
                    for (let i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                    }
                    const byteArray = new Uint8Array(byteNumbers);
                    byteArrays.push(byteArray);
                }
                const blob = new Blob(byteArrays, {type: contentType});
                return blob;
            }

            return b64toBlob(b64Data, contentType);
        },
        updateArtWork(url) {
            const albumCoverBase64 = this.albumCover.split(',')[1];
            const file = this.createBlob(albumCoverBase64, 'image/jpeg');
            const albumArtworkResponse = putAlbumArtwork(url, file);
            albumArtworkResponse
            .then(() => {
                this.desc = 'Vinculando a capa ao álbum...';
            })
            .catch(() => {
                this.notification = {
                    active: true,
                    message: "Ops! Ocorreu um erro na adição da capa do álbum. Tente novamente.",
                    type: 'error'
                }
                this.loading = false;
                window.scrollTo(0, 0);
            })
        },
        updateTrackFile(url, file, index, idAlbum) {
            const trackFileResponse = putTrackFile(url, file);
            trackFileResponse
            .then(() => {
                this.musicsUploaded++;
                if (this.musics.length == this.musicsUploaded) {
                    this.albumDelivery(idAlbum);
                }
            })
            .catch(() => {
                this.notification = {
                    active: true,
                    message: "Ops! Ocorreu um erro na adição das músicas do álbum. Tente novamente.",
                    type: 'error'
                }
                this.loading = false;
                window.scrollTo(0, 0);
                this.deleteAlbumError(idAlbum);
            })
        },
        addTrackFile(idAlbum, idVolume, idTrack, file, index) {
            const trackResponse = getTrackFileUrl(idAlbum, idVolume, idTrack);
            trackResponse
            .then(res => {
                const { data } = res.data;
                this.updateTrackFile(data.url, file, index, idAlbum);
            })
            .catch(() => {
                this.notification = {
                    active: true,
                    message: "Ops! Ocorreu um erro na adição das músicas do álbum. Tente novamente.",
                    type: 'error'
                }
                this.loading = false;
                window.scrollTo(0, 0);
                this.deleteAlbumError(idAlbum);
            })
        },
        addArtwork(idAlbum) {
            const albumResponse = getAlbumArtworkUrl(idAlbum);
            albumResponse
            .then(res => {
                const { data } = res.data;
                this.updateArtWork(data.url);
            })
            .catch(() => {
                this.notification = {
                    active: true,
                    message: "Ops! Ocorreu um erro na adição da capa do álbum. Tente novamente.",
                    type: 'error'
                }
                this.loading = false;
                window.scrollTo(0, 0);
                this.deleteAlbumError(idAlbum);
            })
        },
        createTrack(idVolume, idAlbum) {
            this.desc = `Adicionando as músicas...`;
            let members = [];
            this.musics.forEach((music, index) => {
                members = [];
                if (music.credits.producers.length > 0) {
                    music.credits.producers.forEach(producer => {
                        members.push({
                            "album_member": {
                                "artist_member": {
                                    "no_member": producer
                                }
                            },
                            "member_function": {
                                "id_member_function": 13
                            }
                        })
                    })
                }
                if (music.credits.composers.length > 0) {
                    music.credits.composers.forEach(composer => {
                        members.push({
                            "album_member": {
                                "artist_member": {
                                    "no_member": composer.name
                                },
                                "ds_publisher": composer.publisher
                            },
                            "member_function": {
                                "id_member_function": 3
                            }
                        })
                    })
                }
                if (music.participations.length > 0) {
                    music.participations.forEach(participation => {
                        members.push({
                            "album_member": {
                                "artist_member": {
                                    "no_member": participation.artist
                                }
                            },
                            "member_function": {
                                "id_member_function": participation.type
                            }
                        })
                    })
                }

                const musicData = {
                    "no_title": music.no_title,
                    "version_type": {
                        "id_track_version_type": music.version_type.id_track_version_type
                    },
                    "genre": {
                        "id_genre": music.genre.id_genre
                    },
                    "sub_genre": {
                        "id_genre": music.sub_genre.id_genre
                    },
                    "is_explicit": music.is_explicit,
                    "is_streamable": true,
                    "is_instrumental": music.is_instrumental,
                    "is_downloadable": true,
                    "is_available_separately": true,
                    "nu_order_track": index + 1,
                    "tp_track_price": "HIGH",
                    "language": {
                        "id_language": music.language.id_language
                    },
                    "recording": {
                        "co_isrc": music.recording.co_isrc,
                        "composition": {
                            "no_title": music.recording.composition.no_title,
                            "ds_lyric": music.recording.composition.ds_lyric
                        }
                    }
                };

                if (members.length > 0) {
                    musicData.members = members;
                }
                
                const trackResponse = postTrack(idAlbum, idVolume, musicData);
                trackResponse
                .then(res => {
                    const { data } = res.data;
                    this.addTrackFile(idAlbum, idVolume, data.id_track, music.musicFile, index);
                })
                .catch(() => {
                    this.notification = {
                        active: true,
                        message: "Ops! Ocorreu um erro na adição das músicas do álbum. Tente novamente.",
                        type: 'error'
                    }
                    this.loading = false;
                    this.deleteAlbumError(idAlbum);
                })  
            });
        },
        albumDelivery(idAlbum) {
            const albumResponse = putAlbumDelivery(idAlbum);
            albumResponse
            .then(() => {
                window.scrollTo(0, 0);
                this.notification = {
                    active: true,
                    message: "Álbum cadastrado com sucesso!",
                    type: 'success'
                }
                this.loading = false;
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            })
            .catch(() => {
                this.deleteAlbumError(idAlbum);
            })  
        },
        deleteUpc() {
            this.loading = true;
            const reqArtist = deleteUpc(this.albumDetails.idUpc);
            reqArtist
        },
        updateUpc() {
            const upc = {
            "id": this.albumDetails.idUpc,
            "co_upc": parseInt(this.albumDetails.upcAlbum),
            "id_artist": localStorage.tp_profile == 'Artist' ? parseInt(this.$route.params.id) : 3,
        };
            this.loading = true;
            const reqArtist = putUpc(upc);
            reqArtist
        },
        deleteAlbumError(idAlbum) {
            const albumResponse = deleteAlbum(idAlbum);
            albumResponse
            .then(() => {
                console.log('Album removido');
            })
            .catch(() => {})  
        }
    }
}
</script>

<style lang="scss" scoped>
    .fluid {
        width: 100%;
    }
    .fluid-height {
        height: 100%;
    }
    .cover-size {
        width: 100%;
        max-width: 400px
    }
    ul {
        list-style: none;
        padding: 0;
        margin-top: 24px;

        li {
            border-bottom: 1px solid white;
            padding-bottom: 12px;

            span {
                color: #994995
            }
        }
    }
    .submit-button {
        max-width: 250px !important;
    }

    .button-clear-size {
        width: 150px;
        border-radius: 12px;
    }
    .disc-cover {
        width: 230px;
        height: 225px;
    }
    .border-bottom {
        padding-bottom: 6px;
        border-bottom: 1px solid #fff;
    }
    .album-title {
        font-size: 2rem;
        font-weight: bold;
        line-height: 1.3;
        color: #ffffff;
    }
    .desc-album {
        font-size: 1em;
        text-align: left;
        color: #ffffff;
    }
    .desc-album-opc {
        font-size: 0.75em;
        text-align: left;
        color: #ffffffa1;
    }
    .bg-table {
        background-color: #ffffff00 !important;
    }
    .text-decoration-none {
        text-decoration: none !important;
    }

    @media only screen and (max-width: 600px){
        .submit-button {
            max-width: 100% !important;
        }

        .button-clear-size {
            width: 100% !important;
            border-radius: 12px;
            margin-right: 0px !important;
            margin-top: 24px;
        }
    }
</style>